import React, { useMemo } from 'react';
import { format } from 'date-fns';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoIcon from '@material-ui/icons/Info';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { Separador } from '../../../../styles/GlobalStyles';
import ICliente from '../../../../interfaces/cliente';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Container } from './styles';
import mask from '../../../../utils/masks';

interface IParam {
  cliente: ICliente;
}

const Resumo: React.FC<IParam> = ({ cliente }) => {
  const data = useMemo(() => {
    if (cliente?.data_inicio_agenda)
      return format(
        new Date(cliente?.data_inicio_agenda) || new Date(),
        'dd/MM/yyyy HH:mm',
      );
    return '';
  }, [cliente?.data_inicio_agenda]);

  const periodicidade = useMemo(() => {
    switch (cliente?.periodicidade) {
      case 1:
        return 'DIÁRIO';
      case 7:
        return 'SEMANAL';
      case 14:
        return 'QUINZENAL';
      case 30:
        return 'MENSAL';
      case 60:
        return 'BIMESTRAL';
      case 90:
        return 'TRIMESTRAL';
      case 180:
        return 'SEMESTRAL';
      case 365:
        return 'ANUAL';
      default:
        return '';
    }
  }, [cliente?.periodicidade]);

  const dia_atendimento = useMemo(() => {
    switch (cliente?.dia_atendimento) {
      case 0:
        return 'SEGUNDA-FEIRA';
      case 1:
        return 'TERÇA-FEIRA';
      case 2:
        return 'QUARTA-FEIRA';
      case 3:
        return 'QUINTA-FEIRA';
      case 4:
        return 'SEXTA-FEIRA';
      case 5:
        return 'SÁBADO';
      case 6:
        return 'DOMINGO';
      default:
        return '';
    }
  }, [cliente?.dia_atendimento]);

  return (
    <Container>
      <Separador>
        <h2>
          <PersonIcon />
          &nbsp;DADOS CADASTRAIS
        </h2>
      </Separador>
      <span>{cliente?.pessoa?.nome || ''}</span>
      <span>{cliente?.pessoa?.apelido || ''}</span>

      {/* Se for pessoa jurídica mostra o CNPJ */}
      {!!cliente?.pessoa?.juridica && (
        <span>
          {cliente?.pessoa?.cpf_cnpj
            ? mask({ input: cliente?.pessoa?.cpf_cnpj, tipo: 'cnpj' })
            : 'CNPJ NÃO INFORMADO'}
        </span>
      )}

      {/* Se for pessoa física mostra o CPF */}
      {!cliente?.pessoa?.juridica && (
        <span>
          {cliente?.pessoa?.cpf_cnpj
            ? mask({ input: cliente?.pessoa?.cpf_cnpj, tipo: 'cpf' })
            : 'CPF NÃO INFORMADO'}
        </span>
      )}

      <span>{cliente?.pessoa?.rg_insc || ''}</span>

      <Separador>
        <h2>
          <LocationOnIcon />
          &nbsp;ENDEREÇO
        </h2>
      </Separador>
      <span>
        {`${cliente?.pessoa?.endereco?.logradouro || ''}, ${
          cliente?.pessoa?.endereco?.numero || ''
        }` || ''}
      </span>
      <span>{cliente?.pessoa?.endereco?.bairro || ''}</span>
      <span>{cliente?.pessoa?.endereco?.complemento || ''}</span>
      <span>
        {cliente?.pessoa?.endereco?.cep
          ? mask({ input: cliente?.pessoa?.endereco?.cep, tipo: 'cep' })
          : ''}
      </span>
      <span>{`${cliente?.pessoa?.endereco?.cidade || ''} - ${
        cliente?.pessoa?.endereco?.estado || ''
      }`}</span>
      <Separador>
        <h2>
          <PermContactCalendarIcon />
          &nbsp;CONTATOS
        </h2>
      </Separador>
      <span>
        {cliente?.pessoa?.contato?.telefone
          ? mask({ input: cliente?.pessoa?.contato?.telefone, tipo: 'fone' })
          : ''}
      </span>
      <span>
        {cliente?.pessoa?.contato?.celular
          ? mask({ input: cliente?.pessoa?.contato?.celular, tipo: 'fone' })
          : ''}
      </span>
      <span>{cliente?.pessoa?.contato?.email || ''}</span>
      <span>{cliente?.pessoa?.contato?.outros || ''}</span>
      <Separador>
        <h2>
          <InfoIcon />
          &nbsp;INFORMAÇÕES COMPLEMENTARES
        </h2>
      </Separador>
      <span>{`PRAZO: ${cliente?.nome_prazo || ''}`}</span>
      <span>{`FORMA DE PAGAMENTO: ${
        cliente?.nome_forma_pagamento || ''
      }`}</span>
      <span>{`GRUPO DE CLIENTES: ${cliente?.nome_grupo_clientes || ''}`}</span>
      <span>{`TABELA DE PREÇOS: ${cliente?.nome_tabela_preco || ''}`}</span>
      <span>{`VENDEDOR: ${cliente?.nome_vendedor || ''}`}</span>
      <span>{`OBSERVAÇÃO: ${cliente?.observacao || ''}`}</span>
      <Separador>
        <h2>
          <CalendarIcon />
          &nbsp;AGENDA
        </h2>
      </Separador>
      <span>{`DATA DE INÍCIO: ${data || ''}`}</span>
      <span>{`FREQUÊNCIA DE VISITA:  ${periodicidade || ''}`}</span>
      <span>{`DIA DE VISITA: ${dia_atendimento || ''}`}</span>
    </Container>
  );
};

export default Resumo;
